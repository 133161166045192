import { ElementCategoryData, ElementData } from '@wix/platform-editor-sdk';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { ElementRole } from '../constants/elements';

enum CategoryId {
  Info = 'info',
  Benefits = 'benefits',
}

export const getCategoriesData = (t: FlowAPI['translations']['t']): ElementCategoryData[] => [
  {
    id: CategoryId.Info,
    title: t('blocks.elements-panel.info'),
  },
  {
    id: CategoryId.Benefits,
    title: t('blocks.elements-panel.benefits'),
  },
];

export const getElementsData = (t: FlowAPI['translations']['t']): ElementData[] => [
  {
    label: t('blocks.elements-panel.image'),
    identifier: { role: ElementRole.ImageContainer },
    categoryId: CategoryId.Info,
    index: 0,
    tooltipData: {
      content: t('blocks.elements-panel.image-tooltip'),
    },
  },
  {
    label: t('blocks.elements-panel.badge'),
    identifier: { role: ElementRole.RibbonWidget },
    categoryId: CategoryId.Info,
    index: 1,
  },
  {
    label: t('blocks.elements-panel.plan-name'),
    identifier: { role: ElementRole.PlanName },
    categoryId: CategoryId.Info,
    index: 2,
  },
  {
    label: t('blocks.elements-panel.price'),
    identifier: { role: ElementRole.PricingWidget },
    categoryId: CategoryId.Info,
    index: 3,
  },
  {
    label: t('blocks.elements-panel.description'),
    identifier: { role: ElementRole.Description },
    categoryId: CategoryId.Info,
    index: 4,
  },
  {
    label: t('blocks.elements-panel.plan-duration'),
    identifier: { role: ElementRole.PlanDuration },
    categoryId: CategoryId.Info,
    index: 5,
  },
  {
    label: t('blocks.elements-panel.button'),
    identifier: { role: ElementRole.Button },
    categoryId: CategoryId.Info,
    index: 6,
  },
  {
    label: t('blocks.elements-panel.content-divider'),
    identifier: { role: ElementRole.ContentDividerContainer },
    categoryId: CategoryId.Info,
    index: 7,
  },
  {
    label: t('blocks.elements-panel.benefits-section'),
    identifier: { role: ElementRole.PerksWidget },
    categoryId: CategoryId.Benefits,
    index: 8,
  },
  {
    label: t('blocks.elements-panel.icon'),
    identifier: { role: ElementRole.PerkIcon },
    categoryId: CategoryId.Benefits,
    index: 9,
  },
  {
    label: t('blocks.elements-panel.perk-divider'),
    identifier: { role: ElementRole.PerkDivider },
    categoryId: CategoryId.Benefits,
    index: 10,
  },
];
