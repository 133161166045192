import { AddComponentHandler, ComponentRef, RemoveComponentHandler } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { removeIconSpacing, setIconSpacingToDefault } from '../components/Benefits/layout-utils';
import { ElementRole } from '../constants/elements';
import { getCategoriesData, getElementsData } from './showHideData';

export const openShowHidePanel = (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  flowApi: EditorScriptFlowAPI,
): Promise<void> => {
  const getCollapsedRefComponentByRole = async (role: ElementRole) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponents = await editorSDK.components.refComponents.getCollapsedRefComponents('token', {
      componentRef: widgetRefHost,
      // @ts-expect-error temp until types are GAed
      includeInnerCollapsed: true,
    });
    const collapsedRefComponent = collapsedRefComponents.filter((comp) => comp.role === role);
    return collapsedRefComponent[0].componentRef;
  };

  const getCompToHide = async (componentRef: ComponentRef) => {
    const type = await editorSDK.components.getType('token', { componentRef });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
      : componentRef;
  };

  const showComp = async (componentRef: ComponentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
    return editorSDK.application.livePreview.refresh('token', {
      shouldFetchData: false,
      source: 'AFTER_DB_CHANGE',
    });
  };

  const hideComp = async (componentRef: ComponentRef) => {
    await editorSDK.components.refComponents.collapseReferredComponent('token', {
      componentRef,
    });
  };

  const addCompHandler: AddComponentHandler = async ({ role }) => {
    if (role === ElementRole.PerkIcon) {
      onShowPerkIcon();
    }

    const componentRef = await getCollapsedRefComponentByRole(role as ElementRole);
    return showComp(componentRef);
  };

  const removeCompHandler: RemoveComponentHandler = async (compRef, { role }) => {
    if (role === ElementRole.PerkIcon) {
      onHidePerkIcon();
    }

    const compToHide = await getCompToHide(compRef);
    return hideComp(compToHide);
  };

  const onHidePerkIcon = async () => {
    const [benefitsWidget] = await editorSDK.components.findAllByRole('', {
      controllerRef: widgetRef,
      role: ElementRole.PerksWidget,
    });
    return removeIconSpacing(editorSDK, benefitsWidget);
  };

  const onShowPerkIcon = async () => {
    const [benefitsWidget] = await editorSDK.components.findAllByRole('', {
      controllerRef: widgetRef,
      role: ElementRole.PerksWidget,
    });
    return setIconSpacingToDefault(editorSDK, benefitsWidget);
  };

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    categoriesData: getCategoriesData(flowApi.translations.t),
    elementsData: getElementsData(flowApi.translations.t),
    addComponentHandler: addCompHandler,
    removeComponentHandler: removeCompHandler,
  });
};
